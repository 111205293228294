<template>
  <div id="app">
    <HeaderApp v-if="this.$route.name != 'Login' && this.$route.name != 'PasswordLost' && this.$route.name != 'Passwordreset' "></HeaderApp>
    <div id="content">
      <router-view/>
    </div>
    <FooterApp></FooterApp>
  </div>
</template>

<script>

import HeaderApp from './components/HeaderApp'
import FooterApp from './components/FooterApp'


export default {
  name: 'App',
  components : { HeaderApp, FooterApp},

}
</script>

<style lang="scss">

@font-face {
  font-family: 'OpenSans';
  font-weight: 400;
  src: url("./assets/fonts/OpenSans-Regular.ttf"),
}

@font-face {
  font-family: 'OpenSans_bold';
  font-weight: 700;
  src: url("./assets/fonts/OpenSans-Bold.ttf"),
}

// @font-face {
//   font-family: 'Roboto_light';
//   font-weight: 200;
//   src: url("./assets/font/Roboto-Light.ttf"),
// }


body {
  margin:0;
  padding:0;
}

#app {
  font-size:15px;
  font-family: $font;
  //margin-bottom: 35px;
  position: relative;
  min-height: 100vh;
}

p {
  margin:0;
}

h1 {
  text-align: center;
  color:$secondaryColor;
  font-size:30px;
  font-family: $fontBold;
  margin:0;
}

h2 {
  text-align: center;
  color:$secondaryColor;
  font-size:25px;
  font-family: $font;
  margin:0;
}

.back-btn {
  height:50px;
  width:50px;
  background:url('./assets/images/back-orange.png');
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
  cursor: pointer;
  margin:20px 20px 10px 0;
}

.back-btn:hover {
  background:url('./assets/images/back-blue.png');
  background-repeat: no-repeat;
  background-size: 50px;
  background-position: center;
}

.back-bar {
  display: flex;  
  justify-content: flex-start;
  width:98%;
  margin:0 1%;
}

.actions-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.actions-btn {
    width:30px;
    height:30px;
    cursor: pointer;
    margin:0 10px;
}

input, select, option {
  font-family: $font;
  outline:none;
}

.btn-form {
  width:200px;
  height:50px;
  border-radius:$radius;
  color:#fff;
  background: $secondaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  border:none;
  font-family: $font;
  letter-spacing: 1px;
}

.btn-form:focus {
  outline: none;
}

.btn-form:hover {
  background:$mainColor;
  cursor: pointer;
}

.text-subtitle{
    text-align: center;
    color:$mainColor;
    font-size:30px;
}

.sep-subtitle {
    margin:20px 0;
    height:2px;
    width:100px;
    background:$mainColor;
}

.row {
  display: flex;
  width:100%;
}

.col2 {
  width:50%;
  display: flex;
  flex-direction: column;
}

/* form */

.form-row {
    display: flex;
    width:100%;
    margin:10px 0;
}

.form-col {
    width:50%;
    margin:0 20px;
    display: flex;
    flex-direction: column;
}

.form-col.full-col {
    width:100%;
}

.form-col label, .form-questions-container {
    margin-left:20px;
    color:$secondaryColor;
    font-size:13px;
}

.input-required {
    color:$error;
}

.form-col input, .form-col textarea, .form-questions-container input {
    color:$secondaryColor;
    width:calc(100% - 20px);
    padding:8px 10px;
    border:1px solid $secondaryColor;
    border-radius:$radius;
    outline:none;
}

.form-col select {
    color:$secondaryColor;
    width:100%;
    padding:8px 10px;
    border:1px solid $secondaryColor;
    border-radius:$radius;
}

.form-col input.input-error, .form-col textarea.input-error, .form-col select.input-error, textarea.input-error  {
    border-color:$error;
}

.notice-error {
    color:$error;
    font-size:13px;
    margin-left:20px;
    width:100%;
}

.form-sep {
    width:calc(100% - 40px);
    margin:40px 20px;
    height:1px;
    background: $secondaryColor;
}

.input-container {
    display: flex;
    flex-direction: column;
}

.row-submit {
    width:100%;
    display: flex;
    justify-content: center;
    margin-top:20px;
}

.form-info {
    margin-left: 20px;
    font-size:13px;
    font-style: italic;
    color: $secondaryColor;
}

.form-succes {
    padding:10px;
    color:$succes;
    border:1px solid $succes;
    border-radius:$radius;
    margin-top:20px; 
    text-align: center;

}

.form-failed {
    padding:10px;
    color:$error;
    border:1px solid $error;
    border-radius:$radius;
    margin-top:20px; 
}

.form-failed ul {
    margin:0;
}

.back-btn {
  cursor: pointer;
}


//ADMIN

.admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
}

.table-container {
  width:98%;
  margin:0 1%;
}

.table-container table {
  width:100%;
  border-collapse: collapse;
}

.table-container table th {
  background:$mainColor;
  color:#fff;
  font-weight:400;
  padding:10px;
}

.table-container table td {
  padding:10px;
  text-align: center;
  height:30px;
}

.table-container table tr:nth-child(even) td {
  background:#f6f6f6;
}

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:120px;
}

.error img {
    max-width: 90%;
}

.error p {
    color:$mainColor;
    margin-top:40px;
    font-size: 25px;
    font-family: $fontBold;
}

@media screen and(max-width: 1000px) {
    .row-submit {
        margin-bottom:40px;
    }

    .form-register {
        width:100%;
        margin:0 0 50px 0;
    }

    .form-row {
        flex-direction: column;
        margin:0;
    }

    .form-col {
        width: 100%;
        margin:10px 0;
    }

    .form-sep {
        margin:20px;
    }
}


/* toggle btn */

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input.active + .slider {
  background-color: $mainColor;
}

input:focus + .slider {
  box-shadow: 0 0 1px $mainColor;
}

input.active+ .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

.input-checkbox {
  display: flex;
}

input[type="checkbox"] {
  width:30px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 26px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .text-subtitle {
    font-size:20px;
  }

  .row  {
    flex-direction: column;
  }

  .col2 {
    width:100%;
  }
}


/* page without menu */
    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin:0 5% 0 5%;
        max-width: 90%;
    }

    form {
        margin-top:20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .input-login {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom:20px;
    }

    .input-login  label {
        color:$secondaryColor;
        font-size:20px;
        margin-bottom:10px;
    }

    .input-login input {
        color:$secondaryColor;
        font-size:15px;
        width:300px;
        max-width: 90%;
        border:1px solid $secondaryColor;
        border-radius:$radius;
        padding:10px;
    }

    .input-login input:focus {
        outline: none;
    }

    .header-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height:280px;
        position:relative;
    }

    .logo-container img {
        max-height: 150px;
        width: auto;
    }

    .logo-sep {
        width:2px;
        height:150px;
        margin:0 40px;
        background: $secondaryColor;
    }

    .title-container h1 {
        font-size: 40px;
        color: $secondaryColor;
        font-weight:400;
    }






@media screen and (max-width: 768px) {
    #content .main {
        margin-top:100px;
    }

    form {
        margin-top:0;
    }

    .header-container{ 
        height:150px;
    }

    .logo-container, .title-container {
        width:calc(50% - 21px);
        display: flex;
        justify-content:center;
    }

    .logo-container img {
        max-height:auto;
        max-width: 90%;
    }

    .logo-sep {
        width:2px;
        height:100px;
        margin:0 20px;
    }

    .title-container h1 {
        font-size: 30px;
    }
}


</style>
