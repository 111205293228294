<template>
    <footer>
        <div class="footer-container">
            <p><span>© Copyright {{ currentYear }}</span> <span class="phone-hide"> - </span> <span>BACKBONE - All rights reserved</span> <span class="phone-hide">-</span> <span>Design & Development by <a href="https://www.synthes3dweb.com/" target="_blank">SYNTHES'3D WEB</a></span></p>
        </div>
    </footer>
</template>

<script>

export default {
    name : 'FooterApp',
    data() {
        return {
            currentYear : new Date().getFullYear()
        }
    }

}
</script>

<style lang="scss" scoped>
    footer {
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        background:$mainColor;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#fff;
    }

    .footer-container {
        padding:7px 10px;
        text-align: center;        
    }

    .footer-container p {
        display: flex;
        justify-content: center;
    }

    .footer-container a, .footer-container span {
        color:#fff;
        text-decoration: none;
    }

    .footer-container span {
        margin:0 3px;
    }

    .footer-container a:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        .footer-container {
            font-size:13px;
        }

        .phone-hide {
            display: none;
        }

        .footer-container p {
            flex-direction: column;
        }
    }
</style>