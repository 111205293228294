import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: [ 'AUTH', 'JWT_TOKEN', 'routerHistory' ]
  })],
  state: { 
      //URL : 'http://localhost/backboneAPI_v2/',
      URL : 'https://webinar.backbone.pro/backboneAPI_v2/',
      AUTH : {
        is_loged : false,
        type : null,
        user_id : null
      },
      JWT_TOKEN : null,
      routerHistory: null  

  },
  mutations: {
    login (state, user) {
      state.AUTH.is_loged  = true
      state.AUTH.type = user.type
      state.AUTH.user_id = user.id
      state.JWT_TOKEN = user.token
    },
    logout(state) {
      window.localStorage.removeItem('vuex');
      state.AUTH.is_loged  = false
      state.AUTH.type = null
      state.AUTH.user_id = null
      state.JWT_TOKEN = null
      state.routerHistory= null
    },
    setHistory(state, history) {
      state.routerHistory = history
    }
  },
  getters: {
    getURL :state => {
        return state.URL
    },
    getAuth : state => {
      return state.AUTH
    },
    previousRoute: (state) => {
      if ( !state.routerHistory) return null;
      return  state.routerHistory;
    },
  }
})