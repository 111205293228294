<template>
    <header>
        <div class="logo">
            <router-link to="/login" class="logo-container">
                <img src="./../assets/images/backbone-logo.png" alt="logo BACKBONE" />
            </router-link>
            <div class="logo-sep"></div>
            <div class="title-container">
                <h1>Webinar</h1>
            </div>
        </div>
        <div class="menu">
            <template v-if="this.$store.state.AUTH.type == 1">
                <router-link to="/webinar-list" class="menu-item">My Webinars</router-link>
            </template>
            <template v-if="this.$store.state.AUTH.type == 3">
                <router-link to="/admin/webinar-list" :class="['menu-item', { active : this.$route.name == 'AdminWebinarList' || this.$route.name == 'AdminVideoQuestions' || this.$route.name == 'AdminUsersList' }]">Webinars</router-link>
                <router-link to="/admin/users" class="menu-item">Utilisateurs</router-link>
                <router-link to="/admin/speakers" class="menu-item">Intervenants</router-link>
            </template>
            <div class="logout" v-if="this.$store.state.AUTH.is_loged" @click="logout">Logout</div>
        </div>
        <!-- MENU RESP -->
        <div class="menu-resp" v-if="this.$store.state.AUTH.is_loged">
            <div class="burger-nav" @click="open = !open " :class="open ? 'open' : ''">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <nav class="menu-header-resp-content" v-if="open">
                <router-link v-if="this.$store.state.AUTH.type == 1" to="/webinar-list" class="menu-item">My Webinars</router-link>
                <div class="logout-resp" v-if="this.$store.state.AUTH.is_loged" @click="logout">Logout</div>
            </nav>
        </div>
    </header>
</template>

<script>
import store from './../store'
import router from './../router'

export default {
    name : 'HeaderApp',
    data(){
        return {
            open : false
        }
    },
    methods : {
        logout() {
            store.commit('logout')
            router.push('/login')
        }
    },
    watch : {
         '$route.name' : function() {
             this.open = false
         }
    }
}
</script>

<style lang="scss" scoped>



    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height:100px;
        position:relative;
    }

    .logo {
        display: flex;
        align-items: center;
    }

    .logo-container img {
        max-height: 80px;
        width: auto;
        margin-left: 20px;
    }

    .logo-sep {
        width:2px;
        height:80px;
        margin:0 40px;
        background: $secondaryColor;
    }

    .title-container h1 {
        font-size: 25px;
        color: $secondaryColor;
        font-weight:400;
    }


    .menu {
        display: flex;
        align-items: center;
    }

    .menu-resp {
        display: none;
    }

    .menu a:hover, .menu a.active {
        font-family: $fontBold;
    }

    .menu a:hover:after, .menu a.active:after {
        position: absolute;
        bottom:-10px;
        height:2px;
        width:100%;
        background: $mainColor;
        content:'';
    }

    .menu-item {
        margin:0 10px;
        color:$mainColor;
        text-decoration: none;
        width:100px;
        display: flex;
        justify-content: center;
        line-height: 20px;
        position: relative;
    }

    .logout {
        background: $mainColor;
        color:#fff;
        border-radius: 10px;
        padding:10px 20px 10px 40px;
        margin-right: 20px;
        margin-left: 40px;
        background-image: url('./../assets/images/logout-white.png');
        background-repeat: no-repeat;
        background-position: center left 10px;
        background-size: 25px;
    }

    .logout:hover {
        cursor: pointer;
        background: $secondaryColor;
        background-image: url('./../assets/images/logout-white.png');
        background-repeat: no-repeat;
        background-position: center left 10px;
        background-size: 25px
    }

@media screen and (max-width: 768px) {
    header { 
        height:80px;
        position:fixed;
        top:0;
        left:0;
        right:0;
        background:#fff;
        z-index:9999;
    }

    .logo-container {
        width:100px;
        display: flex;
        justify-content:center;
    }

    .logo-container img {
        max-height:auto;
        max-width: 90%;
    }

    .logo-sep {
        width:2px;
        height:60px;
        margin:0 10px 0 20px;
    }

    .title-container h1 {
        font-size: 20px;
    }

    .menu {
        display:none
    }

    .menu-resp {
        display: flex;
        margin-right:10px;
    }

    .burger-nav {
        width: 40px;
        height: 40px;
        position: relative;
        margin: 50px auto;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
    }

    .burger-nav span {
    display: block;
    position: absolute;
    height: 7px;
    width: 100%;
    background: $mainColor;
    border-radius: 7px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    }

    .burger-nav span:nth-child(1) {
    top: 0px;
    }

    .burger-nav span:nth-child(2),
    .burger-nav span:nth-child(3) {
    top: 14px;
    }

    .burger-nav span:nth-child(4) {
    top: 28px;
    }

    .burger-nav.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
    }

    .burger-nav.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    }

    .burger-nav.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    }

    .burger-nav.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
    }

    .menu-header-resp-content {
    position: fixed;
    top: 80px;
    width: 300px;
    right: 0;
    background: #fff;
    }

    .menu-header-resp-content .menu-item {
        width: 100%;
        border: none;
        height: 40px;
        justify-content: flex-start;
        padding: 0 20px 0 40px;
        align-items: center;
        text-decoration: none;
        margin:0
    }

    .logout-resp {
        background: $mainColor;
        color:#fff;        
        padding:10px 20px 10px 40px;
        background-image: url('./../assets/images/logout-white.png');
        background-repeat: no-repeat;
        background-position: center left 10px;
        background-size: 25px;
    }
    
}
</style>
