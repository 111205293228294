import { extend } from 'vee-validate';
import { required, max, min, digits, email, mimes, size, length, confirmed, dimensions} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('mimes', {
  ...mimes,
  message: 'Le type du fichier est invalide'
});

extend('dimensions', {
  ...dimensions,
  message: 'La taille du fichier n\'est pas valide'
});

extend('size', {
  ...size,
  message: 'La taille du fichier n\'est pas valide'
});

extend('confirmed', {
  ...confirmed,
  message: 'Les emails de passe ne concordent pas'
});

extend('confirmedPwd', {
  ...confirmed,
  message: 'Les password de passe ne concordent pas'
});

extend('max', {
  ...max,
  message: (_, { length }) => `This field can have no more than ${length} caracters`
});

extend('length', {
  ...length,
  message: (_, { length }) => `Ce champs doit comporter  ${length} caractères`
});

extend('min', {
  ...min,
  message: (_, { length }) => `Ce champs doit contenir au moins ${length} caractères`
});

extend('digits', {
  ...digits,
  message: (_, { length }) => `Ce champs doit contenir ${length} chiffres`
});

extend('email', {
  ...email,
  message: 'Invalid email format'
});

extend('date_format', {
  validate: function(value) {
    let parts = value.split('-')
    let month = parseInt(parts[1])
    let day = parseInt(parts[2])
    let year = parseInt(parts[0])
    if(isNaN(month) || isNaN(day) || isNaN(year)){
      return false
    } else if(month < 0 || month > 10) {
      return false
    } else if (day < 0 || day > 31) {
      return false
    } else if( year < 1900 || year > 2100) {
      return false
    } else {
      return true
    }    
  },
  message : 'Le format de la date n\'est pas valide'
});

extend('heure_format', {
  validate(value) {
    let parts = value.split(':')
    let hour = parseInt(parts[0])
    let minutes = parseInt(parts[1])
    if(isNaN(minutes) || isNaN(hour)){
      return false;
    } else if(hour < 0 || hour > 24) {
      return false
    } else if (minutes < 0 || minutes > 60) {
      return false
    } else {
      return true
    }    
  },
  message : 'Le format de l\'heure n\'est pas valide (24h:60min)'
});


extend('checkbox_required', {
  validate(value) {

    return value
  },
  message : 'Vous devez cocher cette case'
});