import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {    
    if (to.hash) {
        return { selector: to.hash }
    } else if (savedPosition) {
        return savedPosition;
    } else {
        return { x: 0, y: 0 }
    }


  },
  routes: [
    {
      path: '*',
      redirect: '/login'
    },
    {
      path: '/webinar/:id',
      name: 'Webinar',
      component: () => import( './views/Webinar.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/webinar-list',
      name: 'WebinarList',
      component: () => import( './views/WebinarList.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/questions/:id',
      name: 'WebinarQuestions',
      component: () => import( './views/WebinarQuestions.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import( './views/Login.vue'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/register/:id',
      name: 'Register',
      component: () => import( './views/Register.vue'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/password-lost',
      name: 'PasswordLost',
      component: () => import( './views/PasswordLost.vue'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/password-reset/:id/:key',
      name: 'Passwordreset',
      component: () => import( './views/PasswordReset.vue'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/create-account',
      name: 'CreateAccount',
      component: () => import( './views/CreateAccount.vue'),
      meta: {
        requiresAuth: false
      }
    },
    //ADMIN
    {
      path: '/admin/webinar-list',
      name: 'AdminWebinarList',
      component: () => import( './views/admin/AdminWebinarList.vue'),
      meta: {
        requiresAdmin: true
      },
    },
    {
      path: '/admin/users-list/:id',
      name: 'AdminUsersList',
      component: () => import( './views/admin/AdminUsersList.vue'),
      meta: {
        requiresAdmin: true
      },
    },
    {
      path: '/admin/webinar-questions/:id',
      name: 'AdminVideoQuestions',
      component: () => import( './views/admin/AdminWebinarVideo.vue'),
      meta: {
        requiresAdmin: true
      },
    },
    {
      path: '/admin/users',
      name: 'AdminUsers',
      component: () => import( './views/admin/AdminUsers.vue'),
      meta: {
        requiresAdmin: true
      },
    },
    {
      path: '/admin/speakers',
      name: 'AdminSpeakers',
      component: () => import( './views/admin/AdminSpeakers.vue'),
      meta: {
        requiresAdmin: true
      },
    },

    //SPEAKER
    {
      path: '/speaker/webinar-list',
      name: 'SpeakerWebinarList',
      component: () => import( './views/speaker/SpeakerWebinarList.vue'),
      meta: {
        requiresSpeaker: true
      },
    },
    {
      path: '/speaker/webinar-answers/:id',
      name: 'SpeakerWebinarAnswers',
      component: () => import( './views/speaker/SpeakerWebinarAnswers.vue'),
      meta: {
        requiresSpeaker: true
      },
    },
    {
      path: '/speaker/login/:id',
      name: 'LoginSpeaker',
      component: () => import( './views/speaker/LoginSpeaker.vue'),
      meta: {
        requiresAuth: false
      },
    },
 
    
  ],
  
})

/* verification qu'on est connecté sinon on redirige */
router.beforeEach((to, from, next) => {
  const currentUser = store.getters.getAuth.is_loged;
  const currentType = store.getters.getAuth.type;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  const requiresSpeaker = to.matched.some(record => record.meta.requiresSpeaker);

  if( (requiresAdmin && currentType != 3 && !currentUser) )  next('/login');
  else if( (requiresSpeaker && currentType != 2 && !currentUser) ) next('/login');
  else if(requiresAuth && !currentUser) next('/login');
  else next();
 
});



export default router;
