import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './vee-validate'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(vueVimeoPlayer)
Vue.use(VueExcelXlsx);

Vue.config.productionTip = false

new Vue({
  router :router,
  store,  
  render: h => h(App)
}).$mount('#app')
